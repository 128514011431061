"use strict";

// ПОДКЛЮЧЕНИЕ

const $ = require("./jquery");
const jQuery = $;
window.jQuery = $;

require("./slick/slick.js");

// автоформы
const autoforms = require("./autoforms");

// maskedInput
const maskedInput = require("./maskedinput");

// отправка форм по аякс
const forms = require("./forms");

// цены
const accounting = require("./accounting");

let simpleTab = require("./simpleTab");

let popupItemsCut = require("./popupItemsCut");

let autoScrollToTag = require("./autoScrollToTag");

// пересчитываемые цифры
let mathAppear = require("./mathAppear");

// галерея
const initPhotoSwipe = require("./photoswipe/initPhotoSwipe");

let liveFilter = require("./liveFilter");

const mobileMenu = require("./mobileMenu");

let miniGallery = require("./miniGallery");

// НАСТРОЙКИ

// плавный скрол к якорям
autoScrollToTag({
	itemTag: ".js-scroll",
	duration: "50",
	speed: "1000",
	ofset: 0,
	parentTag: ".js-scroll-parent",
	noParentOfsetLessWhen: 1150
});

// Форматирование чисел в цене
$(".js-price").each(function(index) {
	let str = $(this).text();
	str = accounting.formatNumber(str);
	$(this).text(str);
});

// Обрабатываем телефон
let telListNode = document.querySelectorAll(".js-tel");
var telList = Array.prototype.slice.call(telListNode, 0);
telList.forEach(function(telInp) {
	maskedInput(telInp).mask("+7 (999) 999-99-99");
});

// Автоформы
let autoformListNode = document.querySelectorAll(".js-autoform");
var autoformList = Array.prototype.slice.call(autoformListNode, 0);

autoformList.forEach(function(form) {
	// console.log(form);
	autoforms.init(form, {
		Validators: {
			maskphone: {
				keys: "1234567890",
				errorMessage: "Type only numbers",
				validatorFunction: function validatorFunction(field) {
					return (field.valid = field.nodeLink.value.indexOf("_") < 0);
				},
				keypressValidatorFunction: false
			}
		}
	});
});

// отправка форм по аякс
forms(".js-form");

// Слайдеры и табы
simpleTab({
	rootTag: ".js-tab",
	nameTag: ".js-tab-control",
	controlGroupTag: ".js-tab-control-group",
	itemTag: ".js-tab-item"
});

// Slick
$(".js-slick").slick({
	slidesToShow: 3,
	variableWidth: true,
	infinite: true,
	responsive: [
		{
			breakpoint: 768,
			settings: {
				arrows: false,
				centerMode: true,
				slidesToShow: 3
			}
		},
		{
			breakpoint: 640,
			settings: {
				arrows: false,
				centerMode: true,
				centerMode: true,
				slidesToShow: 1
			}
		}
	]
});

liveFilter({
	rootTag: ".js-filter",
	itemTag: ".js-filter-item",
	categoryTag: ".js-filter-category",
	controlFormTag: ".js-control-form",
	controlTag: ".js-control",
	delay: 0,
	animationCallback: function aimationCallback(itemNode, result) {
		itemNode.style.display = "none";
		if (result) {
			itemNode.style.display = "";
		}
	},
	afterFiltrationCallback: ""
});

mobileMenu({
	btnOpenTag: ".js-char-open",
	bodyClass: "is-char-open"
});

miniGallery ({
    rootTag: ".js-mini-gallery",
    bigPhotoTag: ".js-big-photo",
    itemTag: ".js-thumbs",
    data: "bigimage"
});