/**
  :: Простой переключатель табов v 1.5::

Важно: все параметры - css-классы

Пример подключения:

let simpleTab = require("./simpleTab");

simpleTab ({
    rootTag: ".js-tabs",
    nameTag: ".js-tab-name",
    controlGroupTag: ".js-tab-group",
    itemTag: ".js-tab-item"
});

*/

module.exports = function(option) {
    // проверка
    if (option.rootTag && option.nameTag && option.itemTag) {
        if (
            document.querySelector(option.rootTag) &&
            document.querySelector(option.nameTag) &&
            document.querySelector(option.itemTag)
        ) {
            run(option);
        } else {
            if (!document.querySelector(option.rootTag)) {
                // console.log("не найден корневой элемент " + option.rootTag);
            } else {
                if (!document.querySelector(option.nameTag)) {
                    console.error(
                        "не найден список названий табов " + option.nameTag
                    );
                }

                if (!document.querySelector(option.itemTag)) {
                    console.error("не найден список табов " + option.itemTag);
                }
            }
        }
    } else {
        console.error("табам не заданы необходимые параметры");
    }

    // запуск слайдера
    function run(option) {
        var currentTab = 0;
        var rootEl = document.querySelector(option.rootTag);

        var controlGroupList = rootEl.querySelectorAll(option.controlGroupTag);
        controlGroupList = Array.from(controlGroupList);

        var itemList = rootEl.querySelectorAll(option.itemTag);
        itemList = Array.from(itemList);

        // основной код
        let removeActive = function() {
            itemList.forEach(function(item, i, arr) {
                item.classList.remove("is-active");
            });
            controlGroupList.forEach(function(controlGroup){
                var nameList = controlGroup.querySelectorAll(option.nameTag);
                nameList = Array.from(nameList);

                nameList.forEach(function(item, i, arr) {
                    item.classList.remove("is-active");
                });
            });
        };

        let goTab = function(i) {
            removeActive();
            itemList[i].classList.add("is-active");

            controlGroupList.forEach(function(controlGroup){
                var nameList = controlGroup.querySelectorAll(option.nameTag);
                nameList = Array.from(nameList);
                nameList[i].classList.add("is-active");
            });
        };

        controlGroupList.forEach(function(controlGroup){
            var nameList = controlGroup.querySelectorAll(option.nameTag);
            nameList = Array.from(nameList);

            // проверка на равенство табов и инициализация событий
            if (nameList.length == itemList.length) {
                nameList.forEach(function(item, i, arr) {
                    nameList[i].onclick = function() {
                        goTab(i);
                    };
                });
            } else {
                console.error(
                    "количество названий табов " +
                        nameList.length +
                        " не рано количеству табов " +
                        itemList.length
                );
            }

            goTab(currentTab);
        });
    }
};
