/**
  :: Мини галлерея 1.1 ::
     Заменяет подложку

Принимает параметры:
    rootTag: корневой элемент
    bigPhotoTag: блок для вывода картинки
    itemTag: тег с фото
    data: название дата-атрибута со ссылкой на большое изображение.

Данные хранятся в itemTag, которые являются блоками с превью.
Внутри itemTag есть дата атрибут data-bigimage в виде ссылки на большое изображение.
Эта же ссылка является бекграундом превью.
По клику на itemTag меняется фон у bigPhotoTag.
Добавляет на миниатюру class "is-active"

Пример подключения:

let miniGallery = require("./miniGallery");

miniGallery ({
    rootTag: ".js-mini-gallery",
    bigPhotoTag: ".js-big-photo",
    itemTag: ".js-thumbs",
    data: "bigimage"
});

*/


module.exports = function (option) {

    // проверка
    if (option.rootTag && option.bigPhotoTag && option.itemTag && option.data) {
        if (document.querySelector(option.rootTag) && document.querySelector(option.bigPhotoTag) && document.querySelector(option.itemTag)) {
            run(option);
        } else {
            if (!document.querySelector(option.rootTag)) {
                // console.log("не найден корневой элемент " + option.rootTag);
            } else {
                if (!document.querySelector(option.bigPhotoTag)) {
                    console.error("не найден блок для вывода картинки " + option.nameTag);
                }

                if (!document.querySelector(option.itemTag)) {
                    console.error("не найдены превью " + option.itemTag);
                }
            }
        }
    } else {
        console.error("минигалерее не заданы необходимые параметры");
    }

    // запуск слайдера
    function run(option) {
        // console.log("Минигалерея " + option.rootTag);
        let rootList = document.querySelectorAll(option.rootTag);
        rootList = Array.from(rootList);

        let init = function(rootEl) {
            let bigPhoto = rootEl.querySelector(option.bigPhotoTag);
            let itemList = rootEl.querySelectorAll(option.itemTag);
            itemList = Array.from(itemList);

            itemList.forEach(function (item, i, arr) {
                item.onclick = function(e) {
                    itemList.forEach(function (item, i, arr) {
                        item.classList.remove("is-active");
                    });
                    let imgUrl = item.getAttribute("data-" + option.data);
                    bigPhoto.style.backgroundImage =  "url("+imgUrl+")";
                    item.classList.add("is-active");
                };
            });

            itemList[0].classList.add("is-active");
        };

        rootList.forEach(function (rootEl, i, arr) {
            init(rootEl);
        });
    }

};


