"use strict";

/* mathAppear.js v1.5 | (c) 2019 @denzakh */

/*!

Подключение CommonJS:

// пересчитываемые цифры
const mathAppear = require("./mathAppear");

// пересчитываемые цифры
mathAppear({
	rootTag: ".js-appear-root",
	itemTag: ".js-appear-num",
	handleTag: ".js-appear-btn",
	time: "1000"
});

 */

(function (root, factory) {
	if (typeof define === 'function' && define.amd) {
		define(factory);
	} else if (typeof exports === 'object') {
		module.exports = factory;
	} else {
		root.mathAppear = factory;
	}
})(this, function(option) {

	var rootTag = option.rootTag || ".js-appear-root";
	var itemTag = option.itemTag || ".js-appear-num";
	var handleTag = option.handleTag || ".js-appear-btn";
	var time = +option.time || 1000;

	if(document.querySelector(rootTag) && document.querySelector(itemTag)) {
		init(option);
	}

	// run
	function init() {

		var numRootList = document.querySelectorAll(rootTag);
		numRootList = Array.from(numRootList);
		var rootObj = [];

		numRootList.forEach(function(numRoot) {
			start(numRoot);
		});

		function start(numRoot) {
			var numElList = numRoot.querySelectorAll(itemTag);
			var numElList = Array.from(numElList);
			var numList = createNumObj(numElList);
			rootObj.push(numList);

			render(numList);

			// если есть управление
			if(document.querySelector(handleTag)) {
				setHandle();
			}
		}

		function createNumObj(numElList) {
			var numList = [];

			numElList.forEach(function(numEl) {
				var numText = numEl.innerHTML;
				var numArr = numText.split(",");
				var num = +numArr[0];
				var residue = (numArr[1]) ? numArr[1] : "";
				var residueLength = residue.length;

				var numObj = {
					el: numEl,
					text: numText,
					num: num,
					residue: residue,
					residueLength: residueLength
				}
				numList.push(numObj);
			});

			return numList;
		}

		function render(numList) {
			numList.forEach(function(numObj) {

				var i = 1;
				var delay = 50; // минимальный шаг для setInterval;
				var step = time / delay; // количество шагов -100 шагов
				var addNumber = numObj.num / step; // добавка - сколько нужно прибавлять к цифре, чтобы за step шагов получить итговую цифру
				numObj.el.innerHTML = "0";
				var currentNum = 0;
				var showNum = 0;
				var showText = 0;

				var int = setInterval(function() {

					if(i <= step) {
						// на каждом шаге прибавляем добавку
						// округляем до целого и показываем
						currentNum = currentNum + addNumber;
						showNum = Math.floor(currentNum);

						// если есть разряды, показываем 5 случайных значений на шаг
						if (numObj.residueLength) {
							for (var j = 1; j <= 5; j++) {
								var min = 0;
								var max = Math.pow(10, numObj.residueLength);
								var residue = Math.floor(Math.random() * (max - min + 1) + min);
								showText = showNum + "," + residue;
								numObj.el.innerHTML = showText;
							}
						} else {
							numObj.el.innerHTML = showNum;
						}
					} else {
						clearInterval(int);
						numObj.el.innerHTML = numObj.text;
					}
					i++;
				}, delay);
			});
		}

		function setHandle() {
			var handleBtnList = document.querySelectorAll(handleTag);
			handleBtnList = Array.from(handleBtnList);

			handleBtnList.forEach(function(handleBtn) {
				handleBtn.addEventListener("click",function() {
					rootObj.forEach(function(numList) {
						render(numList);
					});
				});
			});
		}
	}
});