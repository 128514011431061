/*

    :: popupItems - множество попапов - только открытие ::

Принимает настройки:

rootTag: корень
itemTag: тег пункта
btnTag: тег кнопки открытия (внутри пункта)

Действие: инициализируется для нескольких элементов, открывающих попапы
по клику на btnTag добавляет на item класс "is-popup-open",

Подключение:
let popupItems = require("./popupItemsCut");

Настройки:
popupItems({
    rootTag: ".js-mix-root"
    itemTag: ".js-mix-item",
    btnTag: ".js-popup-mix-open",
});

*/

module.exports = function (option) {

    // проверка
    if (option.rootTag && option.itemTag && option.btnTag) {
        if(document.querySelector(option.itemTag) ) {
            if (document.querySelector(option.itemTag) && document.querySelector(option.btnTag)) {
                run(option);
            } else {
                if (!document.querySelector(option.itemTag)) {
                    console.error("не найден контейнер" + option.itemTag + " в " + option.rootTag);
                }
                if (!document.querySelector(option.btnTag)) {
                    console.error("не найден кнопка открытия" + option.btnTag);
                }
            }
        }
    } else {
        console.error("не заданы параметры попапа");
    }

    // запуск
    function run() {
        let rootEl = document.querySelector(option.rootTag);
        var itemListNode = rootEl.querySelectorAll(option.itemTag);
        var itemList = Array.from(itemListNode);

        // основной код
        run.setInit = function () {

            function openPopup(item) {
                console.log("open");
                item.classList.add('is-popup-open');
            }

            itemList.forEach (function (item) {
                let btn = item.querySelector(option.btnTag);

                btn.onclick = function () {
                   openPopup(item);
                }
            });
        }

        run.setInit();
    }

}
